/** @typedef {import('./typedefs.js').CompareItems} CompareItems */

/**
 * @param {string | null} value
 * @returns {CompareItems}
 */
export default (value) => {
  const { items = [] } = JSON.parse(value || '{}');
  return { items: items.map(({ prid, catalog, type }) => ({ prid, catalog, type })) };
};
