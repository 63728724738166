/** @typedef {import('./typedefs.js').CompareItem} CompareItem */

/**
 * Note: prid and catalog can be string or number, it's why we use loose comparison Note: return null if no item match
 *
 * @param {CompareItem[]} items
 * @param {string} prid
 * @param {string} catalog
 * @returns {CompareItem | null}
 */
export default (items, prid, catalog) =>
  items.find((item) => String(item.prid) === prid && String(item.catalog) === catalog) || null;
