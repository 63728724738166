import translation from '../../helpers/translation/translation.js';
import componentRegistry from '../registry.js';

class PopoverTopVendor {
  #isLoaded = false;
  #isActive = false;
  #topVendorContainer;

  constructor({ el }) {
    this.el = el;
    this.#topVendorContainer = this.el.querySelector('.js-popoverTopVendor-container');

    this.el.addEventListener('mouseenter', this.#showTopVendorContent.bind(this));
    this.el.addEventListener('mouseleave', this.#removeTopVendorContent.bind(this));
    this.#initContent();
  }

  #initContent() {
    this.#topVendorContainer.innerHTML = `
    <div class='f-popover'>
      <div class='popover-topVendor'>
        <div class='bold popover-topVendor-header'>${translation(
          'front.core.views.seller.professional.topvendor.popover.title'
        )}</div>
        <dl class='popover-topVendor-criteria'>
          <dt>${translation('front.core.views.seller.professional.topvendor.popover.criteria')}</dt>
          <dd>${translation('front.core.views.seller.professional.topvendor.popover.highrating')}</dd>
          <dd>${translation('front.core.views.seller.professional.topvendor.popover.quickanswers')}</dd>
          <dd>${translation('front.core.views.seller.professional.topvendor.popover.quality')}</dd>
        </dl>
      </div>
    </div>
  `;
    this.#isLoaded = true;
  }

  #showTopVendorContent(e) {
    if (!this.#isLoaded) {
      this.#initContent();
    }
    if (!this.#isActive) {
      this.#topVendorContainer.classList.add('isActive');
      this.#isActive = true;
    }
  }

  #removeTopVendorContent(e) {
    this.#topVendorContainer.classList.remove('isActive');
    this.#isActive = false;
  }
}

export default IS_MOBILE ? PopoverTopVendor : class {};

if (!IS_MOBILE) {
  componentRegistry.define('js-popoverTopVendor', PopoverTopVendor);
}
