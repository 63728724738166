import AlertDialog from './index.js';

/**
 * Request to assign item to a wishlist
 *
 * @example
 *   new AlertSubscriptionRequest({ prid: '1234', catalog: '1' }).show();
 */
export default class {
  #type;
  #prid;
  #catalog;
  #offer;
  constructor({ type, prid, catalog, offer }) {
    this.#type = type;
    this.#prid = prid;
    this.#catalog = catalog;
    this.#offer = offer;
  }
  get type() {
    return this.#type;
  }
  get prid() {
    return this.#prid;
  }
  get catalog() {
    return this.#catalog;
  }
  get offer() {
    return this.#offer;
  }
  show() {
    const modal = new AlertDialog();
    modal.type = this.type;
    modal.prid = this.prid;
    modal.catalog = this.catalog;
    modal.offer = this.offer;

    return new Promise((resolve, reject) => {
      modal.addEventListener('error', ({ error }) => reject(error));
      modal.addEventListener('complete', () => resolve());
      modal.addEventListener('close', () => reject());
      try {
        modal.showModal();
      } catch (error) {
        reject(error);
      }
    });
  }
}
