import parseCompareItems from './parseCompareItems.js';
import { compareItemsLSKey } from '../../../configuration/Configuration.js';

// When a other tab change localStorage compareItems for a different value, this event is dispatched
self.addEventListener('storage', ({ key, newValue, storageArea, url }) => {
  if (storageArea !== localStorage || key !== compareItemsLSKey) {
    return;
  }

  self.dispatchEvent(
    new CustomEvent('compare', {
      bubbles: true,
      detail: {
        ...parseCompareItems(newValue), // FIXME to provide tracking data, we need an API to retrive "productDataLayer" data for all
        origin: url,
      },
    })
  );
});
