import componentRegistry from '../registry.js';
import MagnificPopup from '../../scripts/common/dynamic-imports/MagnificPopup.js';
import { region } from '../../configuration/Configuration.js';
import renderModal from './popin-kobo-redirect.liquid';
import './popin-kobo-redirect.less';

// Used only for ES and PT
export default class PopinKoboRedirect extends MagnificPopup {
  constructor({ el }) {
    super(el, {
      items: {
        src: renderModal({ url: el.href ? el.href : el.dataset.href, withLogo: region === 'ES' }),
        showCloseBtn: true,
      },
      fixedContentPos: true,
      mainClass: `mfp--modal${IS_MOBILE ? ' mfp--mobile' : ''}`,
    });
  }
}

componentRegistry.define('js-PopinKoboRedirect', PopinKoboRedirect);
