import track from './track.js';
import getComponentID from './getComponentID.js';
import getParentElements from '../dom/getParentElements.js';

export default (type) =>
  document.addEventListener(type, ({ target }) => {
    const attributeName = `data-track-${type}`;
    // Go up the tree to track all the elements with the attribute
    const nodes = [target, ...getParentElements(target)].filter((n) => n.matches?.(`[${attributeName}]`));
    for (const node of nodes) {
      const { action = type, type: trackType = 'event', attributes } = parseData(node.getAttribute(attributeName));

      track({ eventInfo: { eventAction: action, type: trackType }, componentID: getComponentID(node), attributes });
    }
  });

/**
 * @param {string} value Attribute value
 * @returns {{ action: string }}
 * @see https://github.com/jquery/jquery/blob/d0ce00cdfa680f1f0c38460bc51ea14079ae8b07/src/data.js#L20-L43
 */
function parseData(value) {
  // JSON object format `data-track-click='{"action":"test","attributes":{}}'`
  if (value.startsWith('{')) {
    try {
      return JSON.parse(value);
    } catch {
      /* empty */
    }
  }

  // default `data-track-click="test"`
  return { action: value };
}
