import componentRegistry from '../registry.js';
import scrollIntoView from '../../helpers/view/scrollIntoView.js';

/**
 * Js-ScrollTo Handles element click
 *
 * @example
 *   <a href="#somewhere" class="js-ScrollTo">
 *     Got to somewhere else
 *   </a>;
 */

export default class ScrollTo {
  constructor({ el }) {
    el.addEventListener('click', (event) => {
      const id = event.currentTarget.hash?.substr(1) ?? event.currentTarget.dataset?.href.substr(1);
      const target = document.getElementById(id);

      // Scroll to top
      if (id == null || id === '' || target == null) {
        event.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        return;
      }

      if (!target) return;
      event.preventDefault();
      scrollIntoView(target);
    });
  }
}
componentRegistry.define('js-ScrollTo', ScrollTo);
