import componentRegistry from '../registry.js';

export default class TwoHoursDeliveryPromise {
  constructor({ el }) {
    this.el = el;
    this.mentionContentState = false;
    this.$alerts = this.el.querySelectorAll('.notification');
    this.$mentionContent = this.el.querySelector('.js-ProductStimulusChrono-mentionsContent');
    this.$zipInput = this.el.querySelector('.ProductStimulusChrono-zipInput');
    this.zipCodes = this.el.dataset.zipcodes.split(' ');

    document.addEventListener('click', ({ target }) => {
      if (target.closest('.js-ProductStimulusChrono-mentionsOpen')) {
        this.toggleMentions();
      }
      if (target.closest('.ProductStimulusChrono-zipSubmit')) {
        this.isZipValid();
      }
    });
    document.addEventListener('keypress', (e) => {
      if (e.target.closest('.ProductStimulusChrono-zipInput')) {
        this.onKeyPressed(e);
      }
    });
  }

  onKeyPressed(e) {
    if (e.which == 13 || e.keyCode == 13) {
      this.isZipValid();
    }
  }

  isZipValid() {
    var submitZip = this.$zipInput.value.toString();
    this.$alerts.forEach((notif) => {
      notif.classList.remove('isActive');
    });

    if (submitZip.match(/^[0-9]+$/) && submitZip.length === 5) {
      this.el.querySelectorAll('.js-zipValue').forEach((el) => (el.innerHTML = submitZip));

      if (this.zipCodes.includes(submitZip)) {
        this.el.querySelector('.notification--green').classList.add('isActive');
      } else {
        this.el.querySelector('.notification--red').classList.add('isActive');
      }
    } else {
      this.el.querySelector('.notification--yellow').classList.add('isActive');
    }
  }

  toggleMentions() {
    if (this.mentionContentState) {
      this.$mentionContent.classList.remove('isActive');
      this.mentionContentState = false;
    } else {
      this.$mentionContent.classList.add('isActive');
      this.mentionContentState = true;
    }
  }
}

componentRegistry.define('js-ProductStimulusChrono', TwoHoursDeliveryPromise);
