import componentRegistry from '../registry.js';
import Drop from 'tether-drop';

export default class DropTooltip {
  #drop;

  constructor({ el }) {
    const { content, classes, position, offset, targetOffset } = el.dataset;

    this.#drop = new Drop({
      target: el,
      content: document.querySelector(content),
      classes: classes ?? 'drop-theme-arrows',
      position: position ?? 'top middle',
      tetherOptions: {
        offset,
        ...(targetOffset && { targetOffset }),
        constraints: [
          {
            to: 'scrollParent',
            attachment: 'together',
            pin: true,
          },
        ],
      },
      openOn: 'hover click',
      constrainToWindow: true,
    });
  }
}

componentRegistry.define('js-dropTooltip', DropTooltip);
