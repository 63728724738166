import rejectAfterTimeout from '../../helpers/promise/rejectAfterTimeout.js';
import getOneTrust from '../../helpers/vendor/getOneTrust.js';
import componentRegistry from '../registry.js';
import './mediaPlaceholder.less';

class MediaPlaceholder {
  constructor({ el }) {
    this.el = el;
    const { classList } = this.el;
    rejectAfterTimeout(getOneTrust(), 5000)
      .finally(() => classList.remove('loading'))
      .catch(() => classList.add('error'));
    this.el.addEventListener('click', this.#handleOpenCookiePopinClick);
  }

  #handleOpenCookiePopinClick = async (event) => {
    if (!event.target.closest('.js-openCookiePopin')) {
      return;
    }

    event.preventDefault();
    (await getOneTrust()).ToggleInfoDisplay();
  };
}

componentRegistry.define('js-mediaPlaceholder', MediaPlaceholder);
