import componentRegistry from '../registry.js';
import getProducts from '../../scripts/api/layer/get-products.js';
import './layer-switcher.less';

/**
 * Select/Tab layer switcher
 *
 * @class
 * @param {HTMLElement} el The DOM element
 */
export default class LayerSwitcher {
  constructor({ el }) {
    this.el = el;
    this.productsList = el.querySelectorAll('.js-NCarousel');
    this.seeAllLinks = el.querySelector('.js-seeAllLink');
    if (this.seeAllLinks !== null) {
      this.seeAllLinksChildren = [...this.seeAllLinks.children];
    }
    this.tabs = el.querySelectorAll('.js-tabsList li');

    this.seeAllLinksChildren?.forEach((link) => link.setAttribute('aria-hidden', 'false'));
    this.seeAllLinks?.children[0].setAttribute('aria-hidden', 'true');

    this.el.addEventListener('change', (event) => {
      const { selectedIndex } = event.target;
      this.switchLayer(selectedIndex);
    });

    this.tabs?.forEach((tab, selectedIndex) => {
      if (selectedIndex === 0) {
        tab.classList.add('selected');
      }
      tab.addEventListener('click', () => this.switchLayer(selectedIndex));
    });
  }

  /**
   * Toggle the selected layer based on selection of (option/tab)
   *
   * @param {number} selectedIndex
   * @returns {Promise<void>}
   */
  switchLayer = async (selectedIndex) => {
    // switch strate
    this.productsList.forEach((list, index) => {
      if (index !== selectedIndex) {
        list.classList.add('hide');
      } else {
        list.classList.remove('hide');
        this.renderSwitchLayer(selectedIndex);
      }
    });
    // toggle the button for selected option/tab

    this.seeAllLinksChildren.forEach((link, index) => {
      //link.classList.toggle('hidden', index !== selectedIndex);
      index !== selectedIndex ? link.setAttribute('aria-hidden', 'false') : link.setAttribute('aria-hidden', 'true');
    });

    // toggle tabs selected class
    this.tabs?.forEach((tab, index) => tab.classList.toggle('selected', selectedIndex === index));
  };

  /**
   * Replace placeholders with the exact content
   *
   * @param {number} selectedIndex
   * @returns {Promise<void>}
   */
  async renderSwitchLayer(selectedIndex) {
    const selectedProductsList = this.productsList[selectedIndex];
    const { pathId, pageName, localBlockName, lazyloadItems, isLoaded } = selectedProductsList.dataset;
    if (lazyloadItems && !isLoaded) {
      const articles = (JSON.parse(lazyloadItems) || []).map((article) => {
        const [catalog, prid] = article.split('-');
        return { catalog, prid };
      });
      if (articles.length) {
        try {
          const { html } = await getProducts(pathId, pageName, localBlockName, articles);
          const virtualDiv = document.createElement('div');
          virtualDiv.insertAdjacentHTML('afterbegin', html);
          const placeholders = selectedProductsList.querySelectorAll('.js-NCarousel-item-lazyload');
          const items = virtualDiv.querySelectorAll('.js-NCarousel-item');
          placeholders.forEach((placeholder, index) => {
            const item = items[index];
            if (item) {
              placeholder.replaceWith(item);
            } else {
              placeholder.remove();
            }
          });
          virtualDiv.remove();
          selectedProductsList.dataset.isLoaded = true;
        } catch (e) {}
      }
    }
  }
}

componentRegistry.define('js-layerSwitcher', LayerSwitcher);
