import { compareItemsLSKey } from '../../../configuration/Configuration.js';

/** @typedef {import('./typedefs.js').CompareItem} CompareItem */
/** @typedef {import('./typedefs.js').CompareItems} CompareItems */

/**
 * Sort compare items by prid then by catalog (as string)
 *
 * @param {CompareItem} a First element for comparison
 * @param {CompareItem} b Second element for comparison
 * @returns {number}
 */
function sortCompareItems(a, b) {
  if (a.prid < b.prid) {
    return -1;
  }
  if (a.prid > b.prid) {
    return 1;
  }
  if (a.catalog < b.catalog) {
    return -1;
  }
  if (a.catalog > b.catalog) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

/**
 * Write compare items to local storage
 *
 * @param {CompareItem[]} items
 */
export default ({ items }) => {
  localStorage.setItem(
    compareItemsLSKey,
    JSON.stringify({
      items: items
        .slice() // create a copy of the array before sorting in place
        .sort(sortCompareItems) // sort to be sure the value is always the same for same items (but with different order)
        // Store only prid and catalog (important for always get the same value)
        .map(({ prid, catalog, type }) => ({ prid: String(prid), catalog: String(catalog), type: String(type) })),
    })
  );
};
