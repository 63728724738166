import componentRegistry from '../registry.js';
import clearHistory from '../../scripts/api/navigationHistory/clearHistory.js';

const CSS_PREFIX = 'js-NavigationHistory';

export default class NavigationHistory {
  constructor({ el }) {
    this.el = el;
    el.addEventListener('click', this.#clickClearHandler);
  }

  #clickClearHandler = ({ target }) => {
    // Don't wait the server response
    clearHistory();

    const currentTarget = target.closest(`.${CSS_PREFIX}ClearButton`);
    if (!currentTarget) return;

    this.el.style.overflow = 'hidden';
    const animation = this.el.animate?.([{ height: `${this.el.scrollHeight}px` }, { height: '0' }], {
      duration: 300,
      fill: 'forwards',
      easing: 'ease-out',
    });

    if (animation) {
      animation.addEventListener('finish', () => this.el.remove());
    } else {
      this.el.remove();
    }
  };
}

componentRegistry.define(CSS_PREFIX, NavigationHistory);
