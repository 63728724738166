import renderModal from './_AlertPopin.liquid';
import MagnificPopup from '../../scripts/common/dynamic-imports/MagnificPopup.js';
import addAlert from '../../scripts/api/alert/addAlert.js';
import fulfill from '../../helpers/promise/fulfill.js';
import translation from '../../helpers/translation/translation.js';
import Dialog from '../../components/dialog/index.js';
import componentRegistry from '../registry.js';
import parseHTML from '../../helpers/dom/parseHTMLFragment.js';
import iconHTML from '../../helpers/html/icon.js';
import iconCheckURL from '@fnacdarty/fnac-icons/svg/icon_i_102_check_03.svg?svgref';

export default class AlertModal extends Dialog {
  #magnificPopup;
  type;
  prid;
  catalog;
  offer;

  constructor({ el } = {}) {
    super({ el: el || parseHTML(renderModal()).firstElementChild });
    this.el.querySelector('.js-confirmAlert').addEventListener('click', this.#handleClickEvent.bind(this));

    this.#magnificPopup = new MagnificPopup();
  }

  showModal() {
    super.showModal();

    this.#magnificPopup.open({
      items: {
        src: this.el,
      },
      fixedContentPos: true,
      mainClass: 'f-mfp',
      closeBtnInside: true,
      callbacks: {
        close: () => {
          this.close();
        },
      },
    });
  }

  async #handleClickEvent(event) {
    event.preventDefault();
    const button = event.currentTarget;

    // API add to wishlist, ignore response (just wait)
    await fulfill(addAlert(this.type, this.prid, this.catalog, this.offer));

    this.dispatchEvent(new Event('complete'));

    button.outerHTML = `<span class="f-popin-alertProduct-validatedLabel">${iconHTML(iconCheckURL)}${translation(
      'core.product.header.main.left.fnacoffer.buybox.addedalert'
    )}</span>`;
  }

  close() {
    if (!this.open) {
      return;
    }

    super.close();

    this.el.classList.remove('f-wishlist-dialog--open');

    this.#magnificPopup.close();
  }
}

componentRegistry.define('js-alertModal', AlertModal);
