import rejectNonOkResponse from '../rejectNonOkResponse.js';
import { comparePreviewURL } from '../../../configuration/Configuration.js';
import defaultImage from '../../../images/product/default_47x47.gif'; // LiteralSmallImageUrl -> Default_Literal_47x47_ImageUrl

/** @typedef {import('./typedefs.js').CompareItem} CompareItem */

/**
 * Reset compare tool
 *
 * @param {CompareItem[]} items Hash identifier of previous values
 */
export default async ({ items }) => {
  const url = Object.assign(new URL(comparePreviewURL, document.baseURI), {
    search: new URLSearchParams(items.map(({ prid, catalog }) => ['products', `${catalog}-${prid}`])),
  });

  /*
  [
    {
      "prid": 9176351,
      "catalog": 1,
      "name": "Souris Sans Fil Apple Magic Mouse 2 Blanc",
      "imageSrc": "https://static.fnac-static.com/multimedia/Images/FR/NR/e1/cc/73/7589089/1502-1/tsp20151109100145/Apple-Magic-Mouse-2-Blanc.jpg",
      "link": "https://www.fnac.com/Souris-Sans-Fil-Apple-Magic-Mouse-2-Blanc/a9176351/w-4",
      "averageRating": 4.5
    },
    {
      "prid": 2771651,
      "catalog": 1,
      "name": "Apple Magic Mouse",
      "imageSrc": "https://static.fnac-static.com/multimedia/Images/FR/NR/78/a5/29/2729336/1535-1/tsp20150206160701/Apple-Magic-Mouse.jpg",
      "link": "https://www.fnac.com/Apple-Magic-Mouse/a2771651/w-4",
      "averageRating": 4.5
    }
  ]
   */
  const data = await (await rejectNonOkResponse(fetch(url))).json();

  // Fill missing/ghost items (eg. inactive) with fake infos
  return items.map(
    ({ prid, catalog }) =>
      data.find((item) => prid === String(item.prid) && catalog === String(item.catalog)) ?? {
        prid,
        catalog,
        name: '????',
        averageRating: 0,
        imageSrc: defaultImage,
        inactive: true,
      }
  );
};
