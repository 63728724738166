import renderBanner from './_CompareBanner.liquid';
import renderItem from './_CompareItem.liquid';
import renderItemPlaceholder from './_CompareItemPlaceholder.liquid';
import getCompareItems from '../../scripts/api/compare/getCompareItems.js';
import getCompareItemsData from '../../scripts/api/compare/getCompareItemsData.js';
import { compareItemLimit, compareURL } from '../../configuration/Configuration.js';
import renderError from './_CompareError.liquid';
import resetCompare from '../../scripts/api/compare/resetCompare.js';
import '../../scripts/api/compare/compareGlobalEvent.js';
import cancelCompare from '../../scripts/api/compare/cancelCompare.js';
import componentRegistry from '../registry.js';
import getProductDataFromAttributes from '../../helpers/tracking/getProductDataFromAttributes.js';
import parseHTML from '../../helpers/dom/parseHTMLFragment.js';
import './banner.less';
import './table.less';
/** @typedef {import('../../scripts/api/compare/typedefs.js').CompareItem} CompareItem */

const bottomStickyCTA = document.querySelector('.js-bottomSticky');

/** @event compareitemcancel */
export default class ProductCompareBanner {
  #countEl;
  #contentEl;
  #openBannerBtn;
  #submitBtn;
  #itemsList;
  #open = false;
  #hasItems = false; // don't need to store all items (and be exposed to a risk that items list be changed without notification)
  el = null;

  constructor({ el } = {}) {
    this.el = el || parseHTML(renderBanner({ compareURL })).firstElementChild;
    document.adoptNode(this.el); // Needed or el.ownerDocument !== document and el.ownerDocument.defaultView == null Can be removed when use Web Components
    this.#countEl = this.el.querySelector('.f-compare-banner__count');
    this.#contentEl = this.el.querySelector('.f-compare-banner__content');
    this.#openBannerBtn = this.el.querySelector('.js-compareBannerBtn');
    this.#submitBtn = this.el.querySelector('.js-goToCompare');
    this.#itemsList = this.el.querySelector('.f-compare-banner__items');
    this.el.ownerDocument.defaultView.addEventListener('compare', this.#handleCompare.bind(this));
    this.el.ownerDocument.defaultView.addEventListener('compareerror', this.#handleCompareError.bind(this));
    this.#openBannerBtn.addEventListener('click', this.#handleOpenClick.bind(this));
    this.el.addEventListener('reset', this.#handleReset.bind(this));
    this.el.addEventListener('click', this.#handleCancelClick.bind(this));
    this.#updateState(getCompareItems());
    if (IS_MOBILE && bottomStickyCTA) {
      this.el.classList.add('f-compare-bottomStickyCTA');
    }

    // Reroute properties, TODO remove that when use Web Components
    Object.defineProperties(this.el, {
      open: {
        get: () => this.open,
        set: (value) => {
          this.open = value;
        },
        configurable: true,
      },
    });
  }

  async #handleCompare({ detail: { items, origin = null } }) {
    this.#removeErrorMessage(); // always clear error message
    await this.#updateState({ items });
    // if not from other browsing context, expand (will stay hidden if has no items)
    if (origin === null) {
      this.#updateOpenState(true);
    }
  }

  get open() {
    return this.#open;
  }

  set open(value) {
    value = Boolean(value);
    if (value === this.#open) {
      return;
    }
    this.#updateOpenState(value);
  }

  #handleOpenClick() {
    this.#updateOpenState(!this.open);
    this.#removeErrorMessage(); // this interaction also remove the current error (if any)
  }

  // TODO create a dedicated component
  #handleCancelClick(event) {
    const cancelCompareBtn = event.target.closest('.js-removeCompareItem');
    if (!cancelCompareBtn) {
      return;
    }

    const { type } = cancelCompareBtn.dataset;
    // Enrich the first product with attributes (used for the tracking), skip some fields
    const items = [
      {
        ...getProductDataFromAttributes(cancelCompareBtn),
        offer: null,
        type,
      },
    ];

    const beforeEvent = new CustomEvent('beforecomparecancel', {
      bubbles: true,
      cancelable: true,
      detail: {
        items,
      },
    });

    // Is the before event default prevented
    if (!this.el.dispatchEvent(beforeEvent)) {
      return;
    }

    cancelCompare(...items);

    // Lets others know compare item has been canceled
    this.el.dispatchEvent(
      new CustomEvent('compareitemcancel', {
        bubbles: true,
        detail: {
          items,
        },
      })
    );

    // Let's everyone know we compare items (or nothing)
    this.el.ownerDocument.defaultView.dispatchEvent(
      new CustomEvent('compare', {
        bubbles: true,
        detail: getCompareItems(), // FIXME to provide tracking data, we need an API to retrive "productDataLayer" data for all
      })
    );
  }

  // updateState but for error only, show error message, don't touch other infos
  #handleCompareError({
    detail: {
      error: { name },
    },
  }) {
    this.#updateErrorState({ name });
    this.#updateOpenState(true); // force expanded state, but hidden if has no items
  }

  #handleReset(event) {
    // event.preventDefault();

    const items = getCompareItems();

    const beforeEvent = new CustomEvent('beforecomparecancel', {
      bubbles: true,
      cancelable: true,
      detail: items,
    });

    // Is the before event default prevented
    if (!this.el.dispatchEvent(beforeEvent)) {
      return;
    }
    resetCompare();

    // Lets others know compare item has been canceled
    this.el.dispatchEvent(
      new CustomEvent('compareitemcancel', {
        bubbles: true,
        detail: { items },
      })
    );

    // Let's everyone know we compare items (or nothing)
    this.el.ownerDocument.defaultView.dispatchEvent(
      new CustomEvent('compare', {
        bubbles: true,
        detail: getCompareItems(), // FIXME to provide tracking data, we need an API to retrive "productDataLayer" data for all
      })
    );
  }

  #removeErrorMessage() {
    this.#contentEl.querySelector('.f-compare-error')?.remove();
  }

  /** @param {?{ name: string }} error */
  #updateErrorState(error) {
    this.#removeErrorMessage();
    if (error == null) {
      return;
    }
    this.#contentEl.insertAdjacentHTML(
      'afterbegin',
      renderError({
        error,
        itemLimit: compareItemLimit,
      })
    );
  }

  #updateOpenState(open) {
    this.#open = open;
    this.el.classList.toggle('f-compare-banner--opened', this.#hasItems && this.open);
    this.el.classList.toggle('f-compare-banner--closed', this.#hasItems && !this.open);
    this.#openBannerBtn.setAttribute('aria-expanded', this.#hasItems && this.open);
    this.el.dispatchEvent(
      new CustomEvent(this.open ? 'show' : 'close', {
        bubbles: true,
      })
    );
  }

  /**
   * @param {object} param0
   * @param {CompareItem[]} param0.items
   * @private
   */
  async #updateState({ items }) {
    const itemsData = items.length > 0 ? await getCompareItemsData({ items }) : [];

    // FIXME should show gost items that don't have data (invalid or inactive products), to allow the user to remove them
    const itemCount = itemsData.length;
    const hasItems = (this.#hasItems = itemCount > 0);
    const canSubmit = itemCount >= 1;

    this.#updateOpenState(this.open);

    this.#countEl.innerText = `(${hasItems ? itemCount : '--'}/${compareItemLimit})`;
    // for compare boutton style
    this.#submitBtn.disabled = !canSubmit;
    this.#submitBtn.classList.toggle('f-compare-banner__action--disabled', !canSubmit);
    this.#itemsList.innerHTML = [
      // render items
      ...itemsData.map((item) => renderItem({ item })),
      // and placeholders
      ...Array.from({ length: compareItemLimit - itemCount }).map(() => renderItemPlaceholder()),
    ].join('');
  }
}

componentRegistry.define('js-compareBanner', ProductCompareBanner);
