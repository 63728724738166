import translation from '../../helpers/translation/translation.js';
import cancelCompare from '../../scripts/api/compare/cancelCompare.js';
import compare from '../../scripts/api/compare/compare.js';
import getCompareItems from '../../scripts/api/compare/getCompareItems.js';
import findCompareItemFromList from '../../scripts/api/compare/findCompareItemFromList.js';
import '../../scripts/api/compare/compareGlobalEvent.js';
import componentRegistry from '../registry.js';
import getProductDataFromAttributes from '../../helpers/tracking/getProductDataFromAttributes.js';

/** @typedef {import('../../scripts/api/compare/typedefs.js').CompareItem} CompareItem */

// Handle differents styles cases.
const classNames = {
  'f-compare': 'f-compare--added', // Mobile: search
  'thumbnail-btnOpen': 'thumbnail-btnOpen--added', // Desktop: mini FA & thumbnail (search + some other pages)
  'f-toolbarButton': 'f-toolbarButton--added', // Desktop: FA
  'f-faTools__item': 'f-faTools__item--added', // Mobile: FA
};

/**
 * Compare cancel compare item event
 *
 * @type {CustomEvent}
 * @property {object} detail
 * @property {CompareItem[]} detail.items
 * @event compareitemcancel
 */

/**
 * Before compare compare item event This event is cancellable
 *
 * @type {CustomEvent}
 * @property {object} detail
 * @property {CompareItem[]} detail.items
 * @event beforecompareitem
 */

/**
 * Compare compare item event This event is not cancellable
 *
 * @type {CustomEvent}
 * @property {object} detail
 * @property {CompareItem[]} detail.items
 * @event compareitem
 */

/**
 * @fires compareitemcancel
 * @fires beforecompareitem
 * @fires compareitem
 */
export default class ProductCompareButton {
  el = null;
  /**
   * @type {boolean}
   * @private
   */
  #added = false;

  constructor({ el }) {
    this.el = el;

    this.el.addEventListener('click', this.#handleClick.bind(this));
    this.el.ownerDocument.defaultView.addEventListener('compare', this.#handleCompare.bind(this));

    this.#updateState(findCompareItemFromList(getCompareItems().items, this.prid, this.catalog) != null); // get items
  }

  get type() {
    return this.el.dataset.type || null;
  }

  get prid() {
    return this.el.dataset.prid;
  }

  get catalog() {
    return this.el.dataset.catalog;
  }

  async #handleClick(event) {
    // already handled
    if (event.defaultPrevented) {
      return;
    }

    const { type } = this;
    // Enrich the first product with attributes (used for the tracking), skip some fields
    const items = [
      {
        ...getProductDataFromAttributes(this.el),
        offer: null,
        type,
      },
    ];
    const relatedTarget = this.el;

    // If already added, cancel
    if (this.#added) {
      const beforeEvent = new CustomEvent('beforecomparecancel', {
        bubbles: true,
        cancelable: true,
        detail: {
          items,
          relatedTarget,
        },
      });

      // Is the before event default prevented
      if (!this.el.dispatchEvent(beforeEvent)) {
        return;
      }

      cancelCompare(...items);

      // Lets others know compare item has been canceled
      this.el.dispatchEvent(
        new CustomEvent('compareitemcancel', {
          bubbles: true,
          detail: {
            items,
            relatedTarget,
          },
        })
      );
    } else {
      // Lets others know an item has been added to an compare tool
      const beforeEvent = new CustomEvent('beforecompareitem', {
        bubbles: true,
        detail: {
          items,
          relatedTarget,
        },
      });

      // Is the before event default prevented
      if (!this.el.dispatchEvent(beforeEvent)) {
        return;
      }

      try {
        compare(...items);
      } catch (error) {
        this.el.dispatchEvent(
          new CustomEvent('compareerror', {
            bubbles: true,
            detail: { error, relatedTarget },
          })
        );

        return;
      }

      // Lets others know an item has been compare
      this.el.dispatchEvent(
        new CustomEvent('compareitem', {
          bubbles: true,
          detail: {
            items,
            relatedTarget,
          },
        })
      );
    }

    // Let's everyone know we compare items (or nothing)
    this.el.ownerDocument.defaultView.dispatchEvent(
      new CustomEvent('compare', {
        bubbles: true,
        detail: getCompareItems(), // FIXME to provide tracking data, we need an API to retrive "productDataLayer" data for all
      })
    );
  }

  #handleCompare({ detail: { items } }) {
    this.#updateState(findCompareItemFromList(items, this.prid, this.catalog) != null);
  }

  /**
   * @param {boolean} added
   * @private
   */
  #updateState(added = false) {
    this.#added = added;

    const className = Object.keys(classNames).find((className) => this.el.classList.contains(className));
    if (className) {
      this.el.classList.toggle(classNames[className], added);
    }

    this.el.title = added ? translation('nav.core.compare.action.added') : translation('nav.core.compare.action.add'); //nav.core.compare.action.added.short

    this.el.setAttribute('aria-label', this.el.title);
  }
}

componentRegistry.define('js-articleCompare', ProductCompareButton);
