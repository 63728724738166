import componentRegistry from '../registry.js';
import MagnificPopup from '../../scripts/common/dynamic-imports/MagnificPopup.js';

// This file is obsolete
// Use components\get-popin\index.js instead

export const getPopInlineParams = (el) => ({
  items: {
    src: el.getAttribute('href') || el.dataset.href,
    type: 'inline',
  },
  removalDelay: el.dataset.removalDelay || 0,
  midClick: true,
  mainClass: el.dataset.class || '',
  fixedContentPos: true,
});

export default class PopupInline extends MagnificPopup {
  constructor({ el }) {
    super(el, getPopInlineParams(el));
  }
}

componentRegistry.define('js-PopupInline', PopupInline);
