import { trackEvent, trackIntent } from '../../helpers/tracking/track.js';
import digitalDataProduct from '../../helpers/tracking/digitalDataProduct.js';
import getComponentID from '../../helpers/tracking/getComponentID.js';

window.addEventListener('compareitem', ({ detail: { items, relatedTarget } }) =>
  trackEvent('compareitem', getComponentID(relatedTarget) ?? 'Toolbar', {
    item: items.map(({ prid, catalog, offer, availability, nature, eqNatures, sellerTypes }) =>
      digitalDataProduct({ prid, catalog, offer, availability, nature, eqNatures, sellerTypes })
    ),
  })
);

const componentID = 'CompareBanner';
const getElement = (target) => target?.closest('.compare-banner') ?? null; // FIXME use the constructor instead classname for Web Component

window.addEventListener('click', ({ target }) => {
  const component = getElement(target.closest('.js-compareBannerBtn'));
  if (!component) return;
  trackIntent(component.open ? 'open' : 'close', componentID);
});
