/**
 * Reject the given promise after timed out
 *
 * @param {Promise<any>} promise The promise to wait
 * @param {number} timeout Time after reject the promise, in milliseconds
 * @param {any} reason
 * @returns {Promise<any>}
 * @example
 *   rejectAfterTimeout(fetch('/slow'), 5000);
 */
export default (promise, timeout, reason = undefined) =>
  Promise.race([promise, new Promise((resolve, reject) => setTimeout(() => reject(reason), timeout))]);
