import { geocodingTemplateURL } from '../../../configuration/Configuration.js';
import rejectNonOkResponse from '../rejectNonOkResponse.js';
/**
 * @param {string} term
 * @returns {Promise<{ latitude: number; longitude: number }>}
 */
export default async (term) => {
  const { Latitude: latitude, Longitude: longitude } = await (
    await rejectNonOkResponse(fetch(geocodingTemplateURL({ term })))
  ).json();
  return { latitude, longitude };
};
