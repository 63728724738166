import './TwoHoursDeliveryPromiseView.js';
import GetPopin from '../get-popin/index.js';
import componentRegistry from '../registry.js';
import { productPopinTemplateURL } from '../../configuration/Configuration.js';

export default class TwoHoursDeliveryPromiseButton extends GetPopin {
  constructor({ el }) {
    const { href } = el?.dataset ?? {};

    if (href) {
      super({
        el,
        options: {
          content: `<div class="container-iframe">
            <iframe src="${href}" class="mfp-content--iframe" style="width: 800px; height: 600px"></iframe>
            <div class="close closeIframe"></div>
          </div>`,
        },
      });
    } else {
      super({ el, options: { src: productPopinTemplateURL({ name: '2-hours-delivery-promise' }) } });
    }
  }
}

componentRegistry.define('js-ProductStimulusChrono-button', TwoHoursDeliveryPromiseButton);
