import componentRegistry from '../registry.js';
import parseHTMLFragment from '../../helpers/dom/parseHTMLFragment.js';
import translation from '../../helpers/translation/translation.js';
import iconHTML from '../../helpers/html/icon.js';
import iconErrorURL from '@fnacdarty/fnac-icons/svg/error+fill.svg?svgref';
import './flashmessage.less';

export default class FlashMessage {
  #visible = false;
  #timeoutID = null;

  constructor({ el } = {}) {
    this.el =
      el ||
      parseHTMLFragment(
        `<div class="f-flashMessage js-FlashMessage">
        <span class="f-flashMessage-title">${iconHTML(iconErrorURL, {
          className: 'f-flashMessage-icon',
        })} ${translation('core.common.htmlfooter.flashmessage')}</span>
        ${translation('core.common.htmlfooter.flashmessage-title')}
      </div>`
      ).firstElementChild;
    window.addEventListener('error', this.#handleError);
  }

  get visible() {
    return this.#visible;
  }

  set visible(value) {
    this.#visible = value;
    this.el.classList.toggle('f-flashMessage--visible', value);
    clearTimeout(this.#timeoutID);
  }

  #handleError = (event) => {
    // Ignore native errors like uncaught errors, script and image load error, script syntax error, etc.
    if (event.isTrusted) return;

    this.visible = true;
    this.#timeoutID = setTimeout(() => (this.visible = false), 3000);
  };
}

componentRegistry.define('js-FlashMessage', FlashMessage);
