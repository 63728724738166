import { deleteAlertURL } from '../../../configuration/Configuration.js';
import rejectNonOkResponse from '../rejectNonOkResponse.js';
export default (type, prid, catalog, offer = null) =>
  rejectNonOkResponse(
    fetch(deleteAlertURL, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        type,
        prid,
        catalog,
        offer,
      }),
    })
  ).then(() => true);
