import componentRegistry from '../registry.js';
import GetPopin from '../get-popin/index.js';

export default class SortingProductsPopin extends GetPopin {
  constructor({ el }) {
    super({
      el,
      options: {
        src: el.dataset.src,
        wrapper: el.dataset.wrapper,
        beforeOpen: () => {
          window.dispatchEvent(new CustomEvent('sorting-products-popin:open', { bubbles: true }));
        },
      },
    });
  }
}

componentRegistry.define('js-sortingProductsPopin', SortingProductsPopin);
