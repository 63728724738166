import componentRegistry from '../registry.js';
import './sticky-header.less';

export default class StickyHeaderView {
  #productStrates;
  #stickyHeaderItems;
  #headerHeight;
  #stickyHeaderHeight;
  #headerSearchHeight;
  #stickyIds;
  #buyButton;

  /**
   * Constructor
   *
   * @param {object} param0
   * @param {Element} param0.el
   */
  constructor({ el }) {
    this.el = el;
    // elements used in other backbone view methods
    this.#productStrates = document.querySelectorAll('.js-productSection');
    this.#stickyHeaderItems = this.el.querySelectorAll('.stickyHeader__label');

    this.#headerHeight = document.querySelector('.js-header-nav').offsetHeight;
    this.#stickyHeaderHeight = this.el.offsetHeight;
    this.#headerSearchHeight = document.querySelector('.js-Header-search').offsetHeight;
    this.#stickyIds = [...this.#stickyHeaderItems].map((el) => el.hash.substr(1)).filter((id) => id);

    this.#buyButton = this.el.querySelector('.js-ProductBuy-add');

    // global listeners
    // TODO use https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver
    window.addEventListener('scroll', this.#handleScroll);
    window.addEventListener('activeofferchange', this.#handleFormChange);
  }

  #handleFormChange = ({ target }) => {
    const form = target.querySelector('.js-buybox .js-ProductBuy')?.id;
    if (form) {
      this.#buyButton.setAttribute('form', form);
    } else {
      this.#buyButton.removeAttribute('form');
    }
  };

  #handleScroll = (event) => {
    const id = this.#getGetIntersectingStrate()?.id;
    for (const stickyItem of this.#stickyHeaderItems) {
      stickyItem.classList.toggle('active', stickyItem.hash.substr(1) === id);
    }
  };

  #getGetIntersectingStrate() {
    const scrollTop = window.scrollY;
    const headerExtraHeight = window.matchMedia('(min-width: 768px)').matches ? 35 : this.#headerSearchHeight;
    const scrollPosition = scrollTop + this.#headerHeight + headerExtraHeight + this.#stickyHeaderHeight;

    for (const item of this.#productStrates) {
      if (!this.#stickyIds.includes(item.id)) {
        continue;
      }

      const blockPosition = item.offsetTop;

      if (scrollPosition >= blockPosition && scrollPosition < blockPosition + item.offsetHeight) {
        return item;
      }
    }
  }
}
componentRegistry.define('js-stickyHeader', StickyHeaderView);
