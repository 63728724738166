import { addAlertURL } from '../../../configuration/Configuration.js';
import rejectNonOkResponse from '../rejectNonOkResponse.js';
/**
 * @param {string} type Price, availability, news (not supported yet). See also AlertingReferences
 * @param {string} prid
 * @param {string} catalog
 * @param {string} offer
 */
export default (type, prid, catalog, offer = null) =>
  rejectNonOkResponse(
    fetch(addAlertURL, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        type,
        prid,
        catalog,
        offer,
      }),
    })
  ).then(() => true);
