import componentRegistry from '../registry.js';
import Drop from 'tether-drop';
import isFromEscapeKey from '../../helpers/event/is-from-escape-key.js';
import trapFocus from '../../helpers/focus/trapFocus.js';
import getTabbableElements from '../../helpers/focus/getTabbableElements.js';
import './sorting-products.less';

const JS_PREFIX = 'js-sortingProducts';
const CSS_CLASS = 'f-sortingProducts__drop';

export default class SortingProducts {
  constructor({ el }) {
    this.el = el;
    this.contentId = this.el.getAttribute('aria-controls');
    this.content = document.getElementById(this.contentId);
    this.location = this.el.closest('[data-component-id]');
    this.componentId = this.location?.getAttribute('data-component-id');

    if (!this.content) {
      return;
    }

    this.content.dataset.componentId = this.componentId ?? '';

    this.buttons = this.content.querySelectorAll('.js-sortingRecos');
    this.nodeButtons = this.content.querySelectorAll('.js-sortingNode');

    this.drop = new Drop({
      target: this.el,
      content: this.content,
      position: 'bottom right',
      constrainToWindow: false,
      classes: this.content.dataset.class || CSS_CLASS,
    });

    window.addEventListener('sorting-products-popin:open', () => {
      this.drop.close();
    });

    this.drop.on('open', () => {
      this.#dropOpen();
    });

    this.drop.on('close', () => {
      this.#dropClose();
    });

    this.content.addEventListener('keydown', ({ key }) => {
      this.#dropEscape({ key });
    });

    this.buttons.forEach((button) => {
      if (!button.hasAttribute('aria-disabled')) {
        button.addEventListener('click', (el) => {
          this.#updateRecos(el.currentTarget);
        });
      }
    });

    this.nodeButtons.forEach((button) => {
      if (!button.hasAttribute('aria-disabled')) {
        button.addEventListener('click', () => {
          const url = this.el.getAttribute('data-url');
          const suffix = button.getAttribute('data-suffix');
          window.location.href = `${url}${suffix}`;
        });
      }
    });
  }

  #dropOpen() {
    const tabbableElements = getTabbableElements(this.content);
    const [firstTabbable] = tabbableElements;

    firstTabbable.focus();
    trapFocus(this.content);

    this.el.setAttribute('aria-expanded', 'true');
  }

  #dropClose() {
    if (this.#isInViewport(this.el)) {
      this.el.focus();
    }
    this.el.setAttribute('aria-expanded', 'false');
    // this.drop.remove();
  }

  #dropEscape({ key }) {
    isFromEscapeKey({ key }) && this.drop.close();
  }

  #updateRecos(el) {
    const src = el.dataset.src;
    const layer = this.el.closest('.js-lazy-products, .js-lazy-html');
    const lazyLayer = layer.cloneNode(true);

    this.#dropClose();
    this.drop.destroy();
    lazyLayer.dataset.src = src;
    layer.replaceWith(lazyLayer); // TODO: add placeholder or loading and aria-live (https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions)
  }

  #isInViewport(el) {
    const { top, bottom } = el.getBoundingClientRect();
    const { innerHeight } = window;
    return top >= 0 && bottom <= innerHeight;
  }
}

componentRegistry.define(JS_PREFIX, SortingProducts);
