import MagnificPopup from '../../scripts/common/dynamic-imports/MagnificPopup.js';
import componentRegistry from '../registry.js';

export default class StorePickerTeradataButton {
  #magnificPopup = new MagnificPopup();

  constructor({ el }) {
    this.el = el;
    this.el.addEventListener('click', this.#handleClick.bind(this));
  }

  #handleClick(event) {
    event.preventDefault();
    const { href } = this.el.dataset;

    var defaultOptions = {
      key: 'terradata',
      items: {
        src: `<div class="container-iframe"><iframe src="${href}" class="mfp-content--iframe" style="width: 834px; height: 650px"></iframe><div class="close closeIframe"></div></div>`,
      },
      type: 'inline',
    };
    this.magnificPopup.open(defaultOptions);
  }
}

componentRegistry.define('js-ProductRetreat-popup-teradata', StorePickerTeradataButton);
