import { getProductsURL } from '../../../configuration/Configuration.js';
import rejectNonOkResponse from '../rejectNonOkResponse.js';
/**
 * @param {string} pathId
 * @param {string} pageName
 * @param {string} localBlockName
 * @param {{ catalog: string; prid: string }[]} articles
 */
export default async (pathId, pageName, localBlockName, articles) =>
  (
    await rejectNonOkResponse(
      fetch(getProductsURL, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          pathId,
          pageName,
          localBlockName,
          articles,
        }),
      })
    )
  ).json();
