import { getAlertsURL } from '../../../configuration/Configuration.js';
import rejectNonOkResponse from '../rejectNonOkResponse.js';
export default async () => {
  const response = await rejectNonOkResponse(
    fetch(getAlertsURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  );

  /*
{
	"alerts": [
		{
			"type": "price",
			"article": {
				"prid": 10337739,
				"catalog": 1,
				"offer": "00000000-0000-0000-0000-000000000000",
				"title": "Console Nintendo Switch noire avec manettes Joy-Con droite rouge néon et Joy-Con gauche bleue néon",
				"imageUrl": "https://static.fnac-static.com/multimedia/Images/E8/E8/0E/68/426216-1505-1545-1/tsp20180621092817/Console-Nintendo-Switch-noire-avec-manettes-Joy-Con-droite-rouge-neon-et-Joy-Con-gauche-bleue-neon.jpg",
				"price": 299.39,
				"url": "https://jeux-video.fnac.fd-dev.net/Front.Fnaccom/Console-Nintendo-Switch-noire-avec-manettes-Joy-Con-droite-rouge-neon-et-Joy-Con-gauche-bleue-neon/a10337739/w-4"
			}
		}
	]
}
*/

  const { alerts } = await response.json();

  return alerts;
};
