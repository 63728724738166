import setCompareItems from './setCompareItems.js';
import getCompareItems from './getCompareItems.js';
import findCompareItemFromList from './findCompareItemFromList.js';

/** @typedef {import('./typedefs.js').CompareItem} CompareItem */

/**
 * Cancel compare of given items
 *
 * @param {CompareItem[]} cancelItems
 */
export default (...cancelItems) => {
  const { items } = getCompareItems();
  setCompareItems({
    items: items.filter(({ prid, catalog }) => !findCompareItemFromList(cancelItems, prid, catalog)),
  });
};
