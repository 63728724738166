import { serviceWorkerScope, serviceWorkerURL } from '../../configuration/Configuration.js';

export default async () => {
  if (!('serviceWorker' in navigator)) {
    return null;
  }

  // FIXME TMP fix to disable the use of service worker on iOS for now because it's broken: https://github.com/PWA-POLICE/pwa-bugs#problem-navigation-to-a-website-has-infinite-loading
  // Force to remove all active service workers
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    try {
      await navigator.serviceWorker?.getRegistrations().then((rs) => rs.forEach((r) => r.unregister()));
    } catch (error) {
      reportError(error);
    }
    return null;
  }

  try {
    return await navigator.serviceWorker.register(serviceWorkerURL, {
      scope: serviceWorkerScope, // check the Service-Worker-Allowed HTTP header (defined by ServiceWorkersHttpModule)
      updateViaCache: 'all', // use cache for service worker too (because it already use version in path, so it's safe here)
    });
  } catch (error) {
    reportError(error instanceof DOMException ? `DOMException (${error.name} ${error.code}): ${error.message}` : error);
    return null;
  }
};
