import FlashMessageView from '../components/flash-message-view/index.js';
import '../components/get-popin/index.js';
import '../components/media-placeholder/index.js';
import '../components/popup-inline/index.js';
import '../components/lazy-image/index.js';
import '../components/share-button/index.js';
import '../components/countdown/index.js';
import '../components/lazy-html/index.js';
import '../components/lazy-html-cacheable/index.js';
import '../components/sticky-header/index.js';
import '../components/scroll-to/index.js';
import '../components/sorting-products/index.js';
import '../components/other-offers-popin/index.js';
import '../components/layer-switcher/index.js';
import '../components/kamino/index.js';

// flash messages
document.body.appendChild(new FlashMessageView().el);
