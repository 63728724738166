import { searchStoreURL } from '../../../configuration/Configuration.js';
import rejectNonOkResponse from '../rejectNonOkResponse.js';
import geoLocate from '../../api/geolocation/geolocate.js';

export default async ({ term = '', prid, catalog, available = false, oneHour = false, form = null }) => {
  const { latitude, longitude } = await geoLocate(term);

  return (
    await rejectNonOkResponse(
      fetch(searchStoreURL, {
        method: 'POST',
        // Send as application/x-www-form-urlencoded
        body: new URLSearchParams({
          inputValue: term,
          latitude,
          longitude,
          prid,
          catalog,
          onShlef: available,
          isRetreatOneHour: oneHour,
          formId: form,
        }),
      })
    )
  ).text();
};
