import { isMobile } from '../../configuration/Configuration.js';

const mediaQuery = window.matchMedia('(max-width: 768px)');

export const scrollMarginTop = () => {
  const isTablet = !isMobile && mediaQuery.matches;
  return [
    '.js-header-nav',
    '.js-header',
    //isTablet ? '.js-Header-search' : null,
    '.js-stickyHeader', // FA '.js-stickyHeader.stickyHeader--isSticked' doesn't work if the viewport is near to 0
    '.js-Toolbar', // Search / List sticky toolbar
    '.js-searchbar', // Search / List sticky searchbar
    !isMobile && !isTablet ? '.js-PubliStickyNav' : null, // Publi SI
  ]
    .map((selector) => document.querySelector(selector)?.offsetHeight ?? 0)
    .reduce((total, value) => total + value, 0);
};

/**
 * Native scrollTo with callback Scrolls to an element, regarding header height / device FIXME use
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView and
 * https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-margin instead
 *
 * @param {HTMLElement} target DOM element target
 * @param {ScrollBehavior} [behavior=smooth] Determines whether scrolling is instant or animates smoothly. Default is
 *   `smooth`. Default is `smooth`
 * @param {number} [scrollMargin=24] Sets the scroll top margin. Default is `24`
 * @throws Will throw an error if the DOM element target is undefined
 */
export default function (target, behavior = 'smooth', scrollMargin = 24) {
  if (!target) {
    throw new TypeError('target is not defined.');
  }

  // Vérifie si la valeur actuelle de scrollMarginTop est définie
  let currentMarginTop = parseInt(target.style.scrollPaddingTop) || 0;

  // on rajoute les element qui sont fixed
  let newMarginTop = currentMarginTop + scrollMarginTop() + scrollMargin;

  // on ajoute la nouvelle valeur à scrollMarginTop
  document.documentElement.style.scrollPaddingTop = `${newMarginTop}px`;

  target.scrollIntoView({ behavior });
}
