/**
 * Check overall cookies weight and reset them when maxLength is reached.
 *
 * @see https://fnacdarty.atlassian.net/wiki/spaces/FnacFrontEndDocumentation/pages/2505441307/Cookies
 */
import { cookiesHostname, cookiesPath, cookieRules } from '../../configuration/Configuration.js';

const maxLength = 12800; // See doc "Limitation"
// TODO implement cookie count

/** Remove all the cookies until cookies are below limits */
export default function () {
  const cookies = document.cookie;
  const cookiesList = cookies.split('; ').map((pair) => {
    const [name, ...value] = pair.split('=');
    return { name, value: value.join('') };
  });

  const cookiesListSorted = cookiesList
    .map(({ name, value }) => {
      const index = cookieRules.findIndex((rule) => rule.test(name));
      return { name, value, index: index < 0 ? Number.POSITIVE_INFINITY : index };
    })
    .sort((cookieA, cookieB) => cookieB.index - cookieA.index);

  let cookiesLength = cookies.length;
  while (cookiesLength > maxLength && cookiesListSorted.length > 0) {
    const { name, value } = cookiesListSorted.shift();
    removeCookie(name);
    cookiesLength -= name.length + value.length + 3; // '<cookie-name>=<cookie-value>; '
  }
}

/** @param {string} name */
function removeCookie(name) {
  const utcYesterday = new Date(Date.now() - 864e5).toUTCString(); // now - 24h

  document.cookie = `${name}=; domain=${cookiesHostname}; path=${cookiesPath}; expires=${utcYesterday}`;
  document.cookie = `${name}=; domain=${document.location.host}; path=/; expires=${utcYesterday}`; // cookie set with "defaults" (ie: document.cookie="name=value; expires=Tue, 31 Dec 2999 23:59:59 GMT"
  document.cookie = `${name}=; expires=${utcYesterday}`; // host-only cookie https://stackoverflow.com/a/28320172/470117
}
