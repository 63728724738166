import MagnificPopup from '../../scripts/common/dynamic-imports/MagnificPopup.js';
import getPickupStoresModalContent from '../../scripts/api/retraitMagasin/getPickupStoresModalContent.js';
import componentRegistry from '../registry.js';
import Overlay from '../overlay/index.js';

export default class StorePickerButton {
  #popinContent = null;
  #magnificPopup = new MagnificPopup();

  constructor({ el }) {
    this.el = el;
    this.el.addEventListener('click', this.#handleClickEvent.bind(this));
    new URLSearchParams(location.search).has('storepickup') && this.#handleClickEvent();
  }

  get prid() {
    return this.el.dataset.prid;
  }

  get catalog() {
    return this.el.dataset.catalog;
  }

  get storeId() {
    return this.el.dataset.storeId;
  }

  async #handleClickEvent(event) {
    event?.preventDefault();
    const loader = new Overlay();

    loader.show();

    let content;
    try {
      const formID =
        this.el.form
          ?.id; /*if the firmID is null, that means the attribute form is not provided correctly if el is not a descendant of a form, the HTML/backend should provided the correct one*/
      content = await getPickupStoresModalContent({
        prid: this.prid,
        catalog: this.catalog,
        store: this.storeId,
        form: formID,
      });
    } finally {
      loader.hide();
    }

    this.#magnificPopup.open({
      items: {
        src: content,
        type: 'inline',
      },
      callbacks: {
        open: () => {
          this.#popinContent = this.#magnificPopup.instance.content[0];

          this.#popinContent.dispatchEvent(
            new CustomEvent('show', {
              bubbles: true,
            })
          );
        },
        close: () => {
          this.#popinContent.dispatchEvent(
            new CustomEvent('close', {
              bubbles: true,
            })
          );
        },
      },
    });
  }
}

componentRegistry.define('js-ProductRetreat-popup', StorePickerButton);
