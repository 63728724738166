import { trackEvent } from '../../helpers/tracking/track.js';

const componentID = 'StorePicker';
const getElement = (target) => target?.closest('.js-storePicker') ?? null; // FIXME use the constructor instead classname for Web Component

window.addEventListener(
  'submit',
  ({ target, target: { searchTerm, searchResults } }) =>
    getElement(target) && trackEvent('search', componentID, { searchTerm, searchResults })
);
