import { isUserAuthenticated } from '../../configuration/Configuration.js';
import getAlerts from '../../scripts/api/alert/getCacheableAlerts.js';
import getAlert from '../../scripts/api/alert/getCacheableAlert.js';
import deleteAlert from '../../scripts/api/alert/deleteAlert.js';
import translation from '../../helpers/translation/translation.js';
import fulfill from '../../helpers/promise/fulfill.js';
import AlertSubscriptionRequest from '../product-alert-dialog/AlertSubscriptionRequest.js';
import findAlertFromList from '../../scripts/api/alert/findAlertFromList.js';
import componentRegistry from '../registry.js';
import iconHTML from '../../helpers/html/icon.js';
import iconCheckURL from '@fnacdarty/fnac-icons/svg/check.svg?svgref';
import iconAlertURL from '@fnacdarty/fnac-icons/svg/icon_i_068_Alert.svg?svgref';

export default class ProductAlertButton {
  /**
   * @type {Promise<?object> | ?object}
   * @private
   */
  #alert = null;

  constructor({ el }) {
    this.el = el;

    this.el.addEventListener('click', this.#handleClick.bind(this));
    this.el.ownerDocument.defaultView.addEventListener('alertschange', this.#handleAlertsChange.bind(this));

    this.#updateState(isUserAuthenticated ? fulfill(getAlert(this.type, this.prid, this.catalog, this.offer)) : null); // get alert (with cache)
  }

  get type() {
    return this.el.dataset.type || 'availability';
  }

  get prid() {
    return this.el.dataset.prid;
  }

  get catalog() {
    return this.el.dataset.catalog;
  }

  get offer() {
    return this.el.dataset.offer;
  }

  async #handleClick(event) {
    // already handled
    if (event.defaultPrevented) {
      return;
    }

    const { type, prid, catalog, offer } = this;

    // If a alert already exist (wait for it to proceed), remove
    const alert = await this.#alert;
    if (alert) {
      const beforeEvent = new CustomEvent('beforealertremove', {
        bubbles: true,
        cancelable: true,
        detail: {
          prid,
          catalog,
          offer,
        },
      });

      // Is the before event default prevented
      if (!this.el.dispatchEvent(beforeEvent)) {
        return;
      }

      if (!(await fulfill(deleteAlert(type, prid, catalog, offer), false))) {
        // End here, can't remove from alert
        return;
      }

      // Lets others know an item has been removed
      this.el.dispatchEvent(
        new CustomEvent('alertremove', {
          bubbles: true,
          detail: {
            prid,
            catalog,
            offer,
          },
        })
      );
    } else {
      // Lets others know an item has been added to an alert
      const beforeEvent = new CustomEvent('beforealertadd', {
        bubbles: true,
        detail: {
          prid,
          catalog,
          offer,
        },
      });

      // Is the before event default prevented
      if (!this.el.dispatchEvent(beforeEvent)) {
        return;
      }

      // Shortcut when the user is not authenticated
      if (!isUserAuthenticated) {
        window.location = this.el.dataset.authHref; // TODO generate URL with instead signInTemplateURL({ currentUrl: location.href + newreview=catalog-prid})
        return;
      }

      // Request the user to choose which alert to add
      try {
        await new AlertSubscriptionRequest({
          type,
          prid,
          catalog,
          offer,
        }).show();
      } catch {
        // End here, can't add to alert
        return;
      }

      // Lets others know an item has been added to an alert
      this.el.dispatchEvent(
        new CustomEvent('alertadd', {
          bubbles: true,
          detail: {
            prid,
            catalog,
            offer,
          },
        })
      );
    }

    // Let's everyone know alerts change
    this.el.ownerDocument.defaultView.dispatchEvent(
      new CustomEvent('alertschange', {
        bubbles: true,
        detail: await fulfill(getAlerts(true), []), // get alerts (without cache)
      })
    );
  }

  #handleAlertsChange({ detail: alerts }) {
    this.#updateState(findAlertFromList(alerts, this.type, this.prid, this.catalog, this.offer));
  }

  /**
   * @param {object | null} alert
   * @private
   */
  async #updateState(alert = null) {
    this.#alert = alert;
    const resolvedAlert = await alert;

    // Check if there is no other pending promise (set before the current one resolve)
    if (this.#alert !== alert) {
      return;
    }

    // TODO make components that inherit same base
    const label = resolvedAlert
      ? translation('core.product.header.main.left.fnacoffer.buybox.addedalert')
      : translation('core.product.header.main.left.fnacoffer.buybox.addalert');
    // front.core.views.product.fa.webbuybox.basket.addalert

    // Special case for product page toolbar button
    if (this.el.matches('.f-toolbarButton')) {
      this.el.title = label;
      this.el.setAttribute('aria-label', label);
      this.el.classList.toggle('f-toolbarButton--added', resolvedAlert);
    } else {
      this.el.innerHTML = `${iconHTML(resolvedAlert ? iconCheckURL : iconAlertURL, {
        className: 'ff-button-icon icon',
      })}<span class="ff-button-label">${label}</span>`;
      this.el.classList.toggle('ff-button-alert--inactive', resolvedAlert);
    }
  }
}

componentRegistry.define('js-ProductAlert-subscribe', ProductAlertButton);
