import setCompareItems from './setCompareItems.js';
import getCompareItems from './getCompareItems.js';
import findCompareItemFromList from './findCompareItemFromList.js';
import { compareItemLimit } from '../../../configuration/Configuration.js';

/** @typedef {import('./typedefs.js').CompareItem} CompareItem */

const typeMatch = (a, b) => a == null || b == null || a === b;

/**
 * Add items to compare tool
 *
 * @param {CompareItem[]} additionalItems
 */
export default (...additionalItems) => {
  const { items } = getCompareItems();

  for (const { prid, catalog, type } of additionalItems) {
    // Ignore duplicated, compare buttons should allow compare product that already in the list
    if (findCompareItemFromList(items, prid, catalog)) {
      continue;
    }

    if (items.length >= compareItemLimit) {
      throw Object.assign(new Error(`${compareItemLimit} comparable items limit reached`), { name: 'ItemLimitError' });
    }

    // Check type match if items exist
    if (items.length > 0) {
      for (const item of items) {
        if (typeMatch(type, item.type)) continue;

        throw Object.assign(new Error(`${type} and ${item.type} are incomparable types`), {
          name: 'InvalidItemTypeError',
        });
      }
    }

    items.push({ prid: String(prid), catalog: String(catalog), type: String(type) });
  }

  setCompareItems({ items });
};
