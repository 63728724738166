import getPickupStoresListContent from '../../scripts/api/retraitMagasin/getPickupStoresListContent.js';
import componentRegistry from '../registry.js';
import Overlay from '../overlay/index.js';

export default class StorePicker {
  #resultContainer = null;

  constructor({ el }) {
    this.el = el;

    // Until this will be a WebComponent (this instanceof HTMLElement) we need to bridge some properties between this component and it's DOM element:
    // Not each time the popup is open, a new instance is created
    Object.defineProperties(this.el, {
      searchTerm: {
        get: () => this.searchTerm,
      },
      searchResults: {
        get: () => this.searchResults,
      },
      prid: {
        get: () => this.prid,
      },
      catalog: {
        get: () => this.catalog,
      },
    });

    this.el.addEventListener('submit', this.#handleSubmitEvent.bind(this));
    this.el.addEventListener('change', this.#handleFilterChangeEvent.bind(this));
    this.#resultContainer = this.el.querySelector('.js-storePickerResults');
    this.#resultContainer.addEventListener('click', this.#handleStoreInfoClick.bind(this));
  }

  get searchResults() {
    return this.#resultContainer.querySelectorAll('.liStore').length;
  }

  get searchTerm() {
    return this.el.term.value;
  }

  get prid() {
    return this.el.dataset.prid;
  }

  get catalog() {
    return this.el.dataset.catalog;
  }

  get form() {
    return this.el.dataset.form;
  }

  async #handleSubmitEvent(event) {
    event.preventDefault();
    const formData = new FormData(this.el);
    const term = formData.get('term'); // or this.el.term.value

    new Overlay({ container: this.#resultContainer }).show();
    try {
      this.#resultContainer.innerHTML = await getPickupStoresListContent({
        term,
        // Filtering is executed client side only
        prid: this.prid,
        catalog: this.catalog,
        form: this.form,
      });
      this.#applyFilters();
    } catch (error) {
      this.#resultContainer.innerHTML = '<!-- Error -->';
      reportError(error);
    }
  }

  #applyFilters() {
    const formData = new FormData(this.el);
    const filters = formData.getAll('filters') ?? [];
    // no filter = all shown
    // one+ filters = only match all filters in same time are visible
    const hideUnavailable = filters.includes('available');
    const hideNonOneHour = filters.includes('onehour');

    for (const storeElement of this.#resultContainer.querySelectorAll('.liStore')) {
      const onehour = storeElement.hasAttribute('data-onehour');
      const available = storeElement.hasAttribute('data-available');
      storeElement.hidden = (hideUnavailable && !available) || (hideNonOneHour && !onehour);
    }
  }

  #handleFilterChangeEvent({ target }) {
    if (target.name !== 'filters') return;

    this.#applyFilters();
  }

  #handleStoreInfoClick(event) {
    const storeDetailElement = event.target.closest('.liStore')?.querySelector('.divStoreDetail');
    if (!storeDetailElement) {
      return;
    }

    // Ignore if an interactive element is clicked
    if (event.target.closest('a, button, input, textarea')) {
      return;
    }

    const collapsed = storeDetailElement.classList.contains('hide');
    for (const otherStoreDetailElement of this.#resultContainer.querySelectorAll('.liStore .divStoreDetail')) {
      otherStoreDetailElement.classList.toggle('hide', otherStoreDetailElement !== storeDetailElement || !collapsed);
    }
  }
}

componentRegistry.define('js-storePicker', StorePicker);
